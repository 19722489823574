import { ContextualMenu, Dialog, DialogType, IButtonProps } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useComponentLogger } from "cayo.ui";
import React, { FC, useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { Entity } from "../api/cayo-graph";
import { ActionParameters, IActionItemDisplayDescription } from "../api/schema.api";
import { bindingsUtils } from "../scheme/bindings";
import { evaluateJsExpression } from "../scheme/transforms/js-expression-evaluator";
import { Dictionary } from "../types/dictionary";
import { ajaxUtils } from "../utils/ajax-utils";
import downloadUtils from "../utils/download-utils";
import { objectUtils } from "../utils/object-utils";
import { urlUtils } from "../utils/url-utils";
import { IClientComponentParameters } from "./App/app.context";
import { ConfirmationBody } from "./ConfirmationModal";
import Form from "./Form";
import { FormProps, externalSubmitFunc } from "./Form/types";
import useTypeAnnotations from "./GlobalHooks/type-annotations.hook";

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
  height: 100%;
  flex-flow: column;
  position: absolute;
  inset: 0;
`;

type ActionFormProps = {
  actionItemDisplayDescription?: IActionItemDisplayDescription | undefined;
  externalObjects?: Entity[];
  actionParameters?: ActionParameters;
  helpLink?: string;
  multipleTitle?: string;
};

type CollectActionParametersDialogProps = FormProps & IClientComponentParameters & ActionFormProps;

const CollectActionParametersDialog: FC<CollectActionParametersDialogProps> = (props) => {
  const intl = useIntl();
  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");
  const {
    externalObjects,
    actionBuilder,
    submit,
    actionItemDisplayDescription,
    actionParameters,
    helpLink,
  } = props;
  const { uiInteraction } = actionBuilder;
  const { getIconAnnotation } = useTypeAnnotations();
  const log = useComponentLogger(CollectActionParametersDialog);

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: dialogStyles,
      dragOptions,
    }),
    [labelId, subTextId]
  );

  const title = useMemo(() => {
    let result: string;
    if (props.title && props.externalObjects?.length) {
      try {
        result = evaluateJsExpression(props.title, { selectedObjects: props.externalObjects });
      } catch {
        result = `Error: ${props.title}`;
      }
    } else {
      result = props.title ?? intl.formatMessage(confirmMessages.confirm);
    }

    return result;
  }, [props.title]);

  const hasInputControls = props.items && props.items.length > 0;

  const onSubmit = useCallback<externalSubmitFunc>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (object: Entity, skipErrorHandling?: boolean) => {
      if (externalObjects?.length && props.submit) {
        const toSubmit = (o: Entity) => {
          const actualSubmit = objectUtils.cloneObject(submit)!;
          if (urlUtils.hasBindings(actualSubmit!.url!)) {
            actualSubmit!.url = bindingsUtils.resolveExpression(
              o as Dictionary,
              actualSubmit!.url!
            ) as string;
          }

          if (props.submit?.isBatch) {
            object = { ...object, ids: externalObjects.map((o) => o.id) } as any;
          } else if (props.actionParameters?.objectIdPropName) {
            object = {
              ...object,
              SelectedIds: externalObjects
                .map((o) => o[actionParameters!.objectIdPropName])
                .join(";"),
            } as any;
          }
          return actionBuilder.buildSubmitWithResponse(actualSubmit, object);
        };

        const submits = props.submit?.isBatch
          ? [toSubmit(externalObjects[0])]
          : externalObjects.map(toSubmit);

        uiInteraction.setLoading(true);
        return Promise.all(submits)
          .then(async (responses) => {
            const response = ((responses as Response[]).find((r) => r.status >= 400) ||
              responses[0]) as Response;

            const headers: any = ajaxUtils.getHeaders(response);
            let result = {};

            if (headers["content-type"] === "application/octet-stream") {
              result = await response.blob();
              log.debug("blob found");
            } else {
              try {
                result = await response.json();
                log.debug("json result", result);
              } catch (e) {
                log.error("Failed to read response", e);
              }
            }

            if (response.status > 300) {
              return Promise.reject(result);
            }

            if (submit?.isStream && result) {
              downloadUtils.downloadStream(result, headers);
              result = {};
            }

            actionBuilder.processResponse(
              result,
              undefined,
              submit!.response,
              undefined,
              props?.signals
            );

            props?.onSaveComplete && props?.onSaveComplete();

            return Promise.resolve();
          })
          .finally(() => uiInteraction.setLoading(false));
      }

      return Promise.resolve();
    },
    [externalObjects]
  );

  return (
    <Container>
      <Dialog
        hidden={false}
        onDismiss={() => props.onClose && props.onClose()}
        dialogContentProps={{
          type: DialogType.normal,
          title,
          closeButtonAriaLabel: "Close",
          showCloseButton: true,
          topButtonsProps: helpLink ? [ToHelpButton(helpLink)] : undefined,

          styles: {
            content: { minWidth: 400 },
            subText: {
              marginTop: hasInputControls ? 8 : 24,
              textAlign: hasInputControls ? "left" : "center",
            },
          },
        }}
        styles={{
          main: {
            minWidth: props.items!.length >= 1 ? "600px !important" : "400px !important",
            position: "relative",
            minHeight: 120,
          },
        }}
        modalProps={modalProps}
      >
        <ConfirmationBody
          confirmationMessage={props?.description || ""}
          objects={props.externalObjects}
          actionItemDisplayDescription={actionItemDisplayDescription}
        />

        <Form
          {...props}
          getIconAnnotation={getIconAnnotation}
          onSubmit={externalObjects?.length ? onSubmit : props.onSubmit}
          title=""
          className="static-form"
        />
      </Dialog>
    </Container>
  );
};

const dialogStyles = { main: { maxWidth: "460px !important" } };

const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
};

const confirmMessages = defineMessages({
  confirm: {
    defaultMessage: "Confirm",
    id: "confirm-form-save-dialog.confirm",
  },
});

const ToHelpButton = (link: string) => {
  return {
    iconProps: { iconName: "help" },
    onClick: () => {
      const a = document.createElement("a");
      a.href = link;
      a.target = "_blank";
      a.click();
    },
  } as IButtonProps;
};

export default CollectActionParametersDialog;
