import React, { FC, useState } from "react";
import InitialConfigurationWizard from "../Configuration/InitialConfigurationWizard";
import WizardsSchemeHolder from "../Schemes/WizardScheme/WizardsSchemeHolder";
import { ClientWizardsHolder } from "./ClientWizardsHolder";

type WizardStuffProps = { isLoggedIn?: boolean };

const showInitialConfig = !process.env.REACT_APP_HIDE_INITIAL_CONFIG;

export const WizardStuff: FC<WizardStuffProps> = ({ isLoggedIn }) => {
  const [isWizardOpened, setIsWizardOpened] = useState(false);

  return (
    <>
      <WizardsSchemeHolder onWizardAction={(show) => setIsWizardOpened(show)} />
      <ClientWizardsHolder onWizardAction={(show) => setIsWizardOpened(show)} />
      {showInitialConfig && (
        <InitialConfigurationWizard isLoggedIn={isLoggedIn} isVisible={!isWizardOpened} />
      )}
    </>
  );
};
